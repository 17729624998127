<template>
  <div class="content">
    <div class="form">
      <div class="form-item">
        <van-field
          v-model="phone"
          placeholder="手机号"
        />
      </div>
      <div class="form-item identify">
        <van-field
          v-model="code"
          placeholder="验证码"
        />
        <van-button
          color="#1F86FE"
          plain
          @click="sendsms"
        >{{yzcodetext}}</van-button>
      </div>
    </div>
    <div
      class="btn"
      @click="submit"
    >
      登录
    </div>
  </div>
</template>

<script>
import { Field, Toast, Button } from 'vant';
import { smscode } from "@/request/api";
export default {
  components: {
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Button.name]: Button,
  },
  data() {
    return {
      phone: "",
      password: "",
      yzcodetext: "获取验证码",
      codeTime: 0,
      codedisabled: false,
      code: "",
    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    timer() {
      if (this.codeTime > 0) {
        this.codeTime--;
        this.yzcodetext = this.codeTime + "s后";
        setTimeout(this.timer, 1000);
      } else {
        this.codeTime = 0;
        this.yzcodetext = "获取验证码";
        this.codedisabled = false;
      }
    },
    sendsms() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        Toast("请输入正确的手机号")
        return false;
      } else {
        this.codeTime = 60;
        this.codedisabled = true;
        let query = {
          phone: this.phone,
        };
        smscode(query).then((res) => {
          this.timer();
        });
      }
    },
    submit() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        Toast('手机号填写有误');
        return
      }
      if (!this.code) {
        Toast('请填写验证码');
        return
      }
      this.$emit('submit', {
        phone: this.phone,
        code: this.code
      })
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  .form {
    margin-top: 2.13rem;
    padding: 0px 1.07rem;
    .form-item {
      & + .form-item {
        margin-top: 1.07rem;
      }
    }
    .identify {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .van-field {
        width: 14.93rem;
      }
    }
  }
  .btn {
    margin: 0 auto;
    background: #1f86fe;
    border-radius: 2px;
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.67rem;
    width: 91.47%;
    color: #ffffff;
  }

  .van-field {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }
}
/deep/.van-button {
  width: 7.07rem;
}
</style>