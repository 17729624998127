<template>
  <div class="content">
    <div class="tabs">
      <div
        class="tabs-item"
        :class="{'active-item':tabsIndex==0}"
        @click="changeTabs(0)"
      >账号登陆</div>
      <div class="line"></div>
      <div
        class="tabs-item"
        :class="{'active-item':tabsIndex==1}"
        @click="changeTabs(1)"
      >手机登录</div>
    </div>
    <PasswordLogin
      v-if="tabsIndex==0"
      @submit="PasswordLoginSubmit"
    />
    <PhoneLogin
      @submit="PhoneLoginSubmit"
      v-else
    />
  </div>
</template>

<script>
import { Field, Toast } from 'vant';
import PasswordLogin from "./PasswordLogin.vue";
import PhoneLogin from "./PhoneLogin.vue";
import { userlogin, userloginsms } from "@/request/api";
import { setStorage, getStorage } from "@/utils/helper";
export default {
  components: {
    [Field.name]: Field,
    [Toast.name]: Toast,
    PasswordLogin,
    PhoneLogin
  },
  data() {
    return {
      tabsIndex: 0,

    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    changeTabs(e) {
      this.tabsIndex = e
    },
    PasswordLoginSubmit(data) {
      userlogin(data).then((res) => {
        this.setUser(res.data)
      });
    },
    PhoneLoginSubmit(data) {
      userloginsms(data).then((res) => {
        this.setUser(res.data)
      });
    },
    setUser(res) {
      setStorage("admin_user", res);
      setStorage("admin_token", res.token);
      Toast.success('登录成功');
      setTimeout(() => {
        this.$router.replace('/mhome')
      }, 1000);
    },
  }
};
</script>

<style lang="less" scoped>
.content {
  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3.2rem;
    .line {
      width: 2px;
      height: 1.07rem;
      background-color: #979797;
      margin: 0rem 1.07rem;
    }
    .tabs-item {
      font-size: 1.07rem;
      cursor: pointer;
      transition: 0.2s;
      font-weight: 600;
    }
    .active-item {
      transition: 0.2s;
      color: #1f86fe;
    }
  }
}
</style>
