<template>
  <div class="content">
    <Login v-if="type=='login'" />
    <SubmitForm
      @submit="SubmitFormSubmit"
      v-else
    />
  </div>
</template>

<script>
import SubmitForm from "@/components/Msubmit/SubmitForm.vue";
import Login from "@/components/Mlogin/Login.vue";
import { manageUrl } from "@/utils/helper";
import { Toast } from 'vant';
export default {
  components: {
    SubmitForm,
    Login,
    [Toast.name]: Toast,
  },
  data() {
    return {
      type: ""
    };
  },
  created() {
    this.type = this.$route.params.type
  },
  methods: {
    SubmitFormSubmit(data) {
      let str = manageUrl()
      this.$axios({
        url: `${str}/api/v1/company/apply`,
        method: 'post',//可以省略 不写默认是get
        data: data
      }).then(res => {
        console.log(res);
        if (res.code == 0) {
          Toast.success('提交成功');
          setTimeout(() => {
            this.$router.replace('/mhome')
          }, 1000);
        } else {
          Toast(res.error);
        }

      })
    },
  }
};
</script>

<style lang="less" scoped>
.content {
}
</style>
