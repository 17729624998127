<template>
  <div class="content">
    <div class="form">
      <div class="form-item">
        <van-field
          v-model="phone"
          placeholder="手机号"
        />
      </div>
      <div class="form-item">
        <van-field
          type="password"
          v-model="password"
          placeholder="密码"
        />
      </div>
    </div>
    <div
      class="btn"
      @click="submit"
    >
      登录
    </div>
  </div>
</template>

<script>
import { Field, Toast } from 'vant';
export default {
  components: {
    [Field.name]: Field,
    [Toast.name]: Toast,
  },
  data() {
    return {
      phone: "",
      password: ""
    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    submit() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        Toast('手机号填写有误');
        return
      }
      if (!this.password) {
        Toast('请填写密码');
        return
      }
      this.$emit('submit', {
        phone: this.phone,
        password: this.password
      })
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  .form {
    margin-top: 2.13rem;
    padding: 0px 1.07rem;
    .form-item {
      & + .form-item {
        margin-top: 1.07rem;
      }
    }
    .identify {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .van-field {
        width: 11.93rem;
      }
    }
  }
  .btn {
    margin: 0 auto;
    background: #1f86fe;
    border-radius: 2px;
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.67rem;
    width: 91.47%;
    color: #ffffff;
  }

  .van-field {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }
}
</style>