<template>
  <div class="content">
    <div class="title">完成验证 立即体验</div>
    <div class="form">
      <div class="form-item">
        <van-field
          v-model="form.name"
          placeholder="姓名"
        />
      </div>
      <div class="form-item">
        <van-field
          v-model="form.phone"
          placeholder="电话"
        />
      </div>
      <div class="form-item">
        <van-field
          v-model="form.title"
          placeholder="职务"
        />
      </div>
      <div class="form-item">
        <van-field
          v-model="form.company_name"
          placeholder="机构名称"
        />
      </div>
      <div class="form-item">
        <van-field
          v-model="form.address"
          placeholder="机构地址"
          right-icon="arrow-down"
          readonly
          @click="openAreashow"
        />
      </div>
      <div class="form-item">
        <van-field
          type="textarea"
          v-model="form.need"
          placeholder="当前需求"
        />
      </div>
      <div class="form-item identify">
        <van-field
          v-model="code"
          placeholder="请输入用户名"
        />
        <div @click="refreshCode">
          <s-identify :identifyCode="identifyCode"></s-identify>
        </div>
      </div>
    </div>
    <div
      class="btn"
      @click="submit"
    >
      点击验证
    </div>
    <van-popup
      v-model="areashow"
      position="bottom"
    >
      <div>
        <van-picker
          show-toolbar
          value-key="name"
          title="选择地区"
          :columns="columns"
          @cancel="areashow=false"
          @confirm="confirm"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Field, Popup, Picker, Toast } from 'vant';
import citylist from "@/utils/area.json";
import SIdentify from '@/components/identify'
export default {
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
    SIdentify
  },
  data() {
    return {
      form: {},
      areashow: false,
      columns: citylist,
      identifyCodes: "1234567890",
      identifyCode: "",
      code: "",
    };
  },
  created() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  computed: {

  },
  methods: {
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    openAreashow() {
      this.areashow = true
    },
    confirm(e) {
      console.log(e);
      this.form.address = e[0] + e[1] + e[2]
      this.areashow = false
    },

    submit() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.form.phone)) {
        Toast('手机号填写有误');
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
        return
      }
      if (this.code !== this.identifyCode) {
        Toast('图形验证码不一致，请重新填写');
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
        return
      }
      if (!this.form.name) {
        Toast('请填写姓名');
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
        return
      }

      if (!this.form.title) {
        Toast('请填写职务');
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
        return
      }
      if (!this.form.company_name) {
        Toast('请填写机构名称');
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
        return
      }
      if (!this.form.need) {
        Toast('请填写需求');
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
        return
      }
      const data = Object.assign({}, {
        name: this.form.name,
        phone: this.form.phone,
        title: this.form.title,
        need: this.form.need,
        company_name: this.form.company_name,
        address: this.form.address,
      })
      this.$emit('submit', data)

    }
  }
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-top: 3.2rem;
    text-align: center;
    font-size: 1.07rem;
    font-weight: 600;
  }
  .form {
    margin-top: 2.13rem;
    padding: 0px 1.07rem;
    .form-item {
      & + .form-item {
        margin-top: 1.07rem;
      }
    }
    .identify {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .van-field {
        width: 11.93rem;
      }
    }
  }
  .btn {
    margin: 0 auto;
    background: #1f86fe;
    border-radius: 2px;
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.67rem;
    width: 91.47%;
    color: #ffffff;
  }
}
.van-field {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
</style>
